<template>
  <div>
    <el-dialog width="60%" :title="!dataForm.id ? '套餐信息配置' : '查看'" :close-on-click-modal="false" :visible.sync="visible"
      :before-close="handleCloseDialog">
      <h3 style="margin:0 0 0 2vh;padding: 2vh 0; border-top: 1px solid rgba(184, 184, 184, 0.6)">基础信息配置</h3>
      <el-form :model="dataForm" ref="dataForm" label-width="160px" :rules="dataRule">
          <el-form-item label="套餐信息" prop="productId">
          <el-select v-model="dataForm.productId" clearable placeholder="请选择" @change="selectProductChange"
            filterable>
            <el-option v-for="item in productIdOpt" :key="item.productId" :label="item.productId+'('+item.title+')'" :value="item.productId">
            </el-option>
          </el-select>
        </el-form-item>
        <div style="display: flex;">
        <el-form-item label="币种" prop="currencyType">
          <el-input v-model="dataForm.currencyType" disabled placeholder="USD" value="USD"></el-input>
        </el-form-item>
        </div>
        <h3 style="margin:0 0 0 2vh; padding: 3vh 0 0 0; border-top: 1px solid rgba(184, 184, 184, 0.6)">套餐详情 </h3>
        <span style="margin:0 0 12vh 2vh; padding: 0 0 12vh 0; color:red; font-size: 12px;">*请务必仔细正确填写 </span>

        <div style="display: flex;">
          <el-form-item label="第三方标价(USD)：" prop="thirdPrice">
            <el-input v-model="dataForm.thirdPrice" disabled placeholder="第三方标价" ></el-input>
          </el-form-item>
          <el-form-item label="端内标价(USD):" prop="price">
            <el-input v-model="dataForm.price" placeholder="端内标价" ></el-input>
          </el-form-item>
        </div>

        <div style="display: flex; margin-top: 2vh;">
          <el-form-item label="套餐描述:" prop="description" >
            <el-input v-model="dataForm.description" placeholder="套餐描述" ></el-input>
          </el-form-item>
          <el-form-item label="宝石原价(USD):" prop="originalPrice">
            <el-input v-model="dataForm.originalPrice" placeholder="宝石原价" ></el-input>
          </el-form-item>
        </div>

        <div style="display: flex; margin-top: 2vh;">
          <el-form-item label="宝石数量:" prop="balance">
            <el-input v-model="dataForm.balance" placeholder="宝石数量" ></el-input>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <template>
          <el-button @click="handleCloseDialog">取消</el-button>
          <el-button type="primary" @click="beforeSubmit()">确定</el-button>
        </template>

      </span>
    </el-dialog>

  </div>
</template>

<script>

const sourceDataForm = {
  // id: 0,
  productId: null,
  currencyType: null,
  thirdPrice: null,
  price: null,
  description: null,
  originalPrice: null,
  balance: null
}
export default {
  data() {
    var validateMoneyNumber = (rule, value, callback) => { 
      const regex = /^\d+(\.\d{1,2})?$/;  
      if (value && !regex.test(value)) {  
        callback(new Error('请输入一个数字'));  
      } else {  
        callback();  
      }  
    }; 
    var validateRequiredMoneyNumber = (rule, value, callback) => {  
      if(!value){
        callback(new Error('该选项不能为空'));  
      }
      const regex = /^\d+(\.\d{1,2})?$/;  
      if (!regex.test(value)) {  
        callback(new Error('请输入一个数字'));  
      } else {  
        callback();  
      }  
    }; 
    var validateRequiredNumber = (rule, value, callback) => {  
      if(!value){
        callback(new Error('该选项不能为空'));  
      }
      const regex = /^\d+$/; 
      if (!regex.test(value)) {  
        callback(new Error('请输入一个数字'));  
      } else {  
        callback();  
      }  
    }; 
    return {
      id: '',
      visible: false,
      productIdOpt: [],
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataRule: {
        'productId': [
          { required: true, message: '请选择套餐信息', trigger: 'blur' }
        ],
        'price': [
          { validator: validateRequiredMoneyNumber, required: true, message: '端内标价不能为空且必须为小数点后不超过两位数的数字', trigger: 'blur' }
        ],
        'originalPrice': [
          { validator: validateMoneyNumber, required: false, message: '宝石原价必须为小数点后不超过两位数的数字', trigger: 'blur' }
        ],
        'balance': [
          { validator: validateRequiredNumber, required: true, message: '宝石数量不能为空且必须为数字', trigger: 'blur' }
       ]
      },
    }
  },
  created() {
    this.getProductIdList();
  },
  methods: {
    init(row) {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.visible = true
    },

    beforeSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid){
          this.$confirm('当前配置信息是否已确认无误?', '提示', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning'
        }).then(() => {
          this.dataFormSubmit();
        })
        }
      })
    },
    handleCloseDialog() {
      this.visible = false
    },

    getProductIdList() {
      this.$http({
        url: this.$http.adornUrl(`/admin/google-pay/list-google-products`),
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          if (data.result) {
            this.productIdOpt = JSON.parse(JSON.stringify(data.result))
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    selectProductChange(value) {
      const selectedOption = this.productIdOpt.find(item => item.productId === value); 
      this.dataForm.thirdPrice = selectedOption.price
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataForm.country = 'ALL'
          this.dataForm.currencyType = 'USD'
          this.dataForm.price = Number(this.dataForm.price)
          const op = Number(this.dataForm.originalPrice)
          this.dataForm.originalPrice = op === 0 ? null : op
          this.dataForm.thirdPrice = Number(this.dataForm.thirdPrice)
          this.dataForm.balance = Number(this.dataForm.balance)
          this.$http({
            url: this.$http.adornUrl(`/admin/google-pay/insert-product`),
            method: 'post',
            data: this.$http.adornData({
              ...this.dataForm
            })
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.input-width {
  width: 35vw;
  margin-bottom: 2vh;
}
</style>